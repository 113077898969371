function offset(who) {
    var box = who.getBoundingClientRect();
    return {
        top: box.top + window.pageYOffset - document.documentElement.clientTop,
        left: box.left + window.pageXOffset - document.documentElement.clientLeft,
        right: box.right,
        bottom: box.bottom
    };
}

function normalizeEvent(e, r, box) {

    if (window.TouchEvent && e instanceof TouchEvent && e.touches && e.touches.length && e.touches[0].pageX !== 'undefined') {
        var offsetX = Math.floor((e.touches[0].pageX - box.left) / r.width * 10000);
        var offsetY = Math.floor((e.touches[0].pageY - box.top) / r.height * 10000);
        return {offsetX, offsetY};
    } else {
        var offsetX = Math.floor((e.pageX - box.left) / r.width * 10000);
        var offsetY = Math.floor((e.pageY - box.top) / r.height * 10000);
        return {offsetX, offsetY};
    }
}

function warpData(data, r, i) {
    if (data instanceof Array) {
        var list = [];
        for (var i = 0; i < data.length; i++) {
            var item = data[i];
            list.push(warpData(item, r, i));
        }
        return list;
    }
    if (typeof data === "number") {
        return (i & 1 ? r.height : r.width) * data / 10000;
    }
    return data;
}

export {
    normalizeEvent,
    offset,
    warpData
};
