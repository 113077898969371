import defaultPen from './defaultPen.js';
import linePen from './linePen.js';
import ellipsePen from './ellipsePen.js';
import rectPen from './rectPen.js';
import textPen from './textPen.js';
import eraser from './eraser.js';
import toPen from './toPen.js';

let pens = [
    {name: 'defaultPen', pen: defaultPen},
    {name: 'linePen', pen: linePen},
    {name: 'ellipsePen', pen: ellipsePen},
    {name: 'rectPen', pen: rectPen},
    {name: 'textPen', pen: textPen},
    {name: 'eraser', pen: eraser},
    {name: 'toPen', pen: toPen},
];

export default pens ;
