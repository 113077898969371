function textPen(render, resolve, append) {
    this.move = function(bx, by, ex, ey) {
        render([bx, by, ex, ey]);
    };
    this.end = function(bx, by, ex, ey) {
        var input = document.createElement("textarea");
        input.addEventListener("blur", function() {
            if (!input.value){
                try { dom.removeChild(input); } catch (error) {}
                return
            }
            resolve([bx, by, ex, ey, input.value]);
            try { dom.removeChild(input); } catch (error) {}
        });
        input.addEventListener("keyup", function(e) {
            if (e.ctrlKey && e.keyCode == 13) {
                resolve([bx, by, ex, ey, input.value]);
                try { dom.removeChild(input); } catch (error) {}
            } else {
                render([bx, by, ex, ey, input.value]);
            }
        });
        var dom = append(input, bx, by);
        input.focus();
    };
}
textPen.render = function(ctx, data, style) {
    if (data && data.length >= 4) {
        let { customFontSize, fillStyle, strokeStyle } = style;
        let a;
        if (!customFontSize){
            customFontSize = parseInt(ctx.font,10)
            a = customFontSize
        }
        if (fillStyle) { ctx.fillStyle = fillStyle; }
        if (strokeStyle) { ctx.strokeStyle = strokeStyle; }
        if (!ctx.canvas.parentNode.offsetWidth) return;
        a = Math.round( customFontSize * ctx.canvas.parentNode.offsetWidth / 1000);
        ctx.font = a + 'px serif'

        ctx.beginPath();
        var bx = data[0],
            by = data[1],
            ex = data[2],
            ey = data[3];
        var text = data[4] || "";
        var x = data[0];
        var y = data[1];
        var size = parseInt(ctx.font);
        if (data[3] > data[1]) y += size;
        text.split("\n").forEach(function(item, i) {
            ctx.fillText(item, x, y + i * size);
        });
    }
};

export default textPen;
