function Event () {
    let listeners = {}

    return {
        on,
        off,
        once,
        trigger,
        clear
    };

    function on (event, callback) {
        const list = listeners[event] = listeners[event] || []
        list.push(callback)
    }
    function once (event, callback) {
        const list = listeners[event] || []
        const f = function (...args) {
            callback(...args)
            off(event, f)
        };
        list.push(f)
    }
    function off (event, callback) {
        const list = listeners[event] || []
        const idx = list.indexOf(callback)
        if (idx >= 0) {
            list.splice(idx, 1)
        }
    }
    function trigger (event, ...args) {
        const list = listeners[event] || []
        list.forEach(callback => callback(...args))
    }
    function clear () {
        listeners = {}
    }
}

export default Event
