/*
*   createBoard 可读端
* */

import {createApi} from "./repository/api";
import {createPaint} from "./repository/paint";
import {createWebsocket} from "./repository/websocket";
import {KEY_CHANGE_PAGE, R_MODE_LISTEN, R_MODE_PAGE, R_MODE_TIME, KEY_START, KEY_CLOSE} from "./constant";

function createRBoard (options) {
    const {dom, token, id, apiDomain = 'https://service.myun.tv', wsUrl = 'wss://msg-ws.myun.tv', defaultMode = { mode: R_MODE_LISTEN }} = options;
    const ws = options.ws || createWebsocket(wsUrl, id);
    const paint = createPaint({dom});
    const api = createApi(apiDomain, token);
    const eightHoursTimestamp = 8 * 60 * 60 * 1000;// 后端记录的options的timestamp忘记加时区了  而这个api用了一段时间了  只能将错就错
    const noRenderAction = [KEY_CHANGE_PAGE, KEY_START, KEY_CLOSE]

    let currentPage;
    let boardData = [];
    let delay = 0;
    let currentMode = '';

    _init();

    return {
        setMode: options => setMode(options),
        getCurrentPage,
        resize,
        destroy
    };

    function setMode({mode, ...options}) {
        currentMode = mode;

        if (currentMode === R_MODE_LISTEN) {
            _setDelay(options.delay || 0);
            currentPage = _getCurrentPageByOptions(boardData);
            setTimeout(() => {
                _renderByPage(currentPage);
            }, delay)
        }

        if (currentMode === R_MODE_PAGE) {
            _setDelay(options.delay || 0);
            currentPage = options.page;
            _renderByPage(currentPage);
        }

        if (currentMode === R_MODE_TIME) {
            _updateByTime(options.timestamp);
        }
    }

    function resize() {
        paint && paint.render();
    }

    function getCurrentPage() {
        return currentPage;
    }

    function destroy() {
        paint && paint.destroy();
        ws.unsubscribe();
        ws.close();
    }

    function _init() {
        paint.setDisable(true);
        api.getBoardData(id, res => {
            boardData = _dealOptions(res.options);
            currentPage = _getCurrentPageByOptions(boardData) || 1;
            boardData.forEach(option => {
                if (option.page === currentPage && noRenderAction.indexOf(option.data.key) < 0) {
                    paint.addUndoHistory(option.data);
                }
            });
            setMode(defaultMode);
            paint.render();
            _subscribe();
        })
    }

    function _setDelay(time) {
        delay = time;
    }

    function _subscribe() {
        ws.subscribe(msg => {
            if (!msg) return;
            if (msg instanceof Array) { // 因为createWSOfUpdate中update的存在, 可能msg会是完整的BoardData， 需要去重
                const newBoardData = _dealOptions(msg);
                boardData = Object.assign(boardData, newBoardData);
            } else {
                let tempData = msg;
                if (typeof tempData === 'string') { tempData = JSON.parse(msg) }
                if (typeof tempData.data === 'string') { tempData.data = JSON.parse(tempData.data)}
                boardData.push({data: tempData.data, page: tempData.page, timestamp: tempData.timestamp});
            }

            if (currentMode === R_MODE_LISTEN) { currentPage = _getCurrentPageByOptions(boardData); }
            if (currentMode !== R_MODE_TIME) {
                setTimeout(() => {
                    _renderByPage(currentPage);
                }, delay)
            }
        });
    }

    function _renderByPage(page) {
        paint.clearOptions();
        boardData.forEach(item => {
            if (item.page === page && noRenderAction.indexOf(item.data.key) < 0) { paint.addUndoHistory(item.data); }
        });
        paint.render();
    }

    function _updateByTime(nowTime) {
        if (!boardData) return;
        
        let currentPageByTime = 1,
            filterBoardDate = boardData.filter(option => option.timestamp <= parseInt(nowTime)) || []

        let currentChangePageInfo = filterBoardDate.reverse().find(option => option.data.key === KEY_CHANGE_PAGE)

        if (currentChangePageInfo) {
            // 如果时间点之前有翻页记录则为使用过的文档: 展示当前时间节点之前最新页码
            currentPageByTime = currentChangePageInfo.data.data[1]
        } else {
            // 如果不存在则属于首页或单板类型: 直接展示第一个操作信息的page即可
            filterBoardDate.length && (currentPageByTime = filterBoardDate[0].page)
        }
       
        currentPage = currentPageByTime

        paint.clearOptions();
        boardData.forEach(item => {
            if (item.page === currentPage && item.timestamp <= parseInt(nowTime) && noRenderAction.indexOf(item.data.key) < 0) {
                paint.addUndoHistory(item.data);
            }
        });
        paint.render();
    }
    
    function _dealOptions(options) {
        if (!options ) { options = [] }
        return options.map(option => {
            if (typeof option.data == 'string') { option.data = JSON.parse(option.data) }
            if (typeof option.timestamp === 'string') { 
                option.timestamp = new Date(option.timestamp).getTime() - (/\+08:00$/.test(option.timestamp) ? 0 : eightHoursTimestamp) 
            }
            return option;
        });
    }

    function _getCurrentPageByOptions(boardData) {
        let newestPage = 1;
        boardData.forEach(option => {
            if (option.data.key === KEY_CHANGE_PAGE) { newestPage = option.data.data[1] }
        });
        return newestPage;
    }
}

export {
    createRBoard
}
