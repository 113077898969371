function linePen(render, resolve) {
    this.move = function(bx, by, ex, ey) {
        render([bx, by, ex, ey]);
    };
    this.end = function(bx, by, ex, ey) {
        resolve([bx, by, ex, ey]);
    };
}
linePen.moveBegin = true;
linePen.render = function(ctx, data, style) {
    if (data && data.length === 4) {
        let { customLineWidth, fillStyle, strokeStyle } = style;
        if (!customLineWidth){ customLineWidth = ctx.lineWidth }
        if (fillStyle) { ctx.fillStyle = fillStyle; }
        if (strokeStyle) { ctx.strokeStyle = strokeStyle; }
        if (!ctx.canvas.parentNode.offsetWidth) return;
        ctx.lineWidth = Math.round( customLineWidth * ctx.canvas.parentNode.offsetWidth / 1000)

        ctx.beginPath();
        ctx.moveTo(data[0], data[1]);
        ctx.lineTo(data[2], data[3]);
        ctx.stroke();
    }
};

export default linePen;
