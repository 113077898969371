function createWSOfPush () {
    let currentCallback = function () {};

    return {
        subscribe,
        unsubscribe,
        close,
        push
    };

    function push(data) {
        currentCallback(data);
    }

    function subscribe (callback) {
        currentCallback = callback;
    }

    function unsubscribe () {
        currentCallback = function () {};
    }

    function close () {}
}

export {
    createWSOfPush
}
