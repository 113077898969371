function ellipsePen(render, resolve) {
    this.move = function(bx, by, ex, ey) {
        render([bx, by, ex, ey]);
    };
    this.end = function(bx, by, ex, ey) {
        resolve([bx, by, ex, ey]);
    };
}
ellipsePen.moveBegin = true;
ellipsePen.render = function(ctx, data, style) {
    if (data && data.length === 4) {
        let { customLineWidth, fillStyle, strokeStyle } = style;
        if (!customLineWidth){ customLineWidth = ctx.lineWidth }
        if (fillStyle) { ctx.fillStyle = fillStyle; }
        if (strokeStyle) { ctx.strokeStyle = strokeStyle; }
        if (!ctx.canvas.parentNode.offsetWidth) return;
        ctx.lineWidth = Math.round( customLineWidth * ctx.canvas.parentNode.offsetWidth / 1000)

        var x = (data[0] + data[2]) / 2;
        var y = (data[1] + data[3]) / 2;
        var a = (data[2] - data[0]) / 2;
        var b = (data[3] - data[1]) / 2;

        var r = (a > b) ? a : b;
        var ratioX = a / r;
        var ratioY = b / r;
        ctx.scale(ratioX, ratioY);
        ctx.beginPath();
        ctx.arc(x / ratioX, y / ratioY, Math.abs(r), 0, 2 * Math.PI, false);
        ctx.closePath();
        ctx.stroke();
    }
};

export default ellipsePen;
