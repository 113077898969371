const Language = {
    zh: {
        color: "颜色",
        defaultPen: "画笔",
        eraser: "橡皮",
        font: "文字",
        line: "直线",
        arrow: "单箭头",
        ellipse: "圆形",
        rect: "矩形",
        move: "移动",
        zoomIn: "放大",
        zoomOut: "缩小",
        reset: "还原",
        undo: "上一步",
        redo: "下一步",
        clear: "清空",
        clearConfirmText: "你确定清空此页文档标记么?",
        clearConfirm: "确定",
        clearConfirmCancel: "取消",
        clearWarn: "已删除！"
    },
    en: {
        color: "Color",
        defaultPen: "Pen",
        eraser: "Eraser",
        font: "Text",
        line: "Line",
        arrow: "Arrow",
        ellipse: "Ellipse",
        rect: "Rectangle",
        move: "Move",
        zoomIn: "Zoom In",
        zoomOut: "Zoom Out",
        reset: "Reset",
        undo: "Undo",
        redo: "Redo",
        clear: "Clear",
        clearConfirmText: "Are you sure you want to clear this page?",
        clearConfirm: "Sure",
        clearConfirmCancel: "Cancel",
        clearWarn: "Delete!"
    },
};

export {
    Language
}
