function eraser(render, resolve) {
    var li = [];
    this.begin = function(bx, by, ex, ey) {
        li.push(bx);
        li.push(by);
    };
    this.move = function(bx, by, ex, ey) {
        li.push(ex);
        li.push(ey);
        render(li);
    };
    this.end = function(bx, by, ex, ey) {
        li.push(ex);
        li.push(ey);
        resolve(li);
    };
}
// 鼠标move时触发this.begin
eraser.moveBegin = true;
// 鼠标out时触发this.end
eraser.outEnd = true;
eraser.render = function(ctx, data, style) {
    let { customEraser } = style;
    if (data instanceof Array && data.length > 0) {
        let a;
        if (!customEraser){
            customEraser = ctx.eraser;
            a = customEraser
        }
        if (!ctx.canvas.parentNode.offsetWidth) return;
        a = Math.round( customEraser * ctx.canvas.parentNode.offsetWidth / 1000 / 2);
        ctx.save();
        ctx.beginPath();
        ctx.arc(data[0],data[1], a, 0, 2 * Math.PI);
        ctx.clip();
        ctx.clearRect(0,0,ctx.canvas.width,ctx.canvas.height);
        ctx.restore();

        for (var i = 2; i < data.length; i += 2) {
            var x1 = data[i-2]
            var y1 = data[i-1]
            var x2 = data[i]
            var y2 = data[i+1]

            var asin = a*Math.sin(Math.atan((y2-y1)/(x2-x1)));
            var acos = a*Math.cos(Math.atan((y2-y1)/(x2-x1)))
            var x3 = x1+asin;
            var y3 = y1-acos;
            var x4 = x1-asin;
            var y4 = y1+acos;
            var x5 = x2+asin;
            var y5 = y2-acos;
            var x6 = x2-asin;
            var y6 = y2+acos;

            //保证线条的连贯，所以在矩形一端画圆
            ctx.save()
            ctx.beginPath()
            ctx.arc(x2,y2,a,0,2*Math.PI);
            ctx.clip()
            ctx.clearRect(0,0,ctx.canvas.width,ctx.canvas.height);
            ctx.restore();

            //清除矩形剪辑区域里的像素
            ctx.save()
            ctx.beginPath()
            ctx.moveTo(x3,y3);
            ctx.lineTo(x5,y5);
            ctx.lineTo(x6,y6);
            ctx.lineTo(x4,y4);
            ctx.closePath();
            ctx.clip()
            ctx.clearRect(0,0,ctx.canvas.width,ctx.canvas.height);
            ctx.restore();

        }
    }
};

export default eraser;
