function toPen(render, resolve, append) {
    this.move = function (bx, by, ex, ey) {
        render([bx, by, ex, ey]);
    };
    this.end = function (bx, by, ex, ey) {
        resolve([bx, by, ex, ey]);
    };
}
toPen.render = function (ctx, data,style) {
    if (data && data.length >= 4) {
        drawArrow(ctx,data[0],data[1],data[2],data[3], null, null, style)
    }
}


function drawArrow(ctx, fromX, fromY, toX, toY, theta, headlen, style) {
    let { customLineWidth, fillStyle, strokeStyle } = style;
    if (!customLineWidth){ customLineWidth = ctx.lineWidth }
    if (fillStyle) { ctx.fillStyle = fillStyle; }
    if (strokeStyle) { ctx.strokeStyle = strokeStyle; }
    if (!ctx.canvas.parentNode.offsetWidth) return;
    ctx.lineWidth = Math.round( customLineWidth * ctx.canvas.parentNode.offsetWidth / 1000)

    theta =  theta || 30;
    headlen = headlen || 3*ctx.lineWidth;
    var angle = Math.atan2(fromY - toY, fromX - toX) * 180 / Math.PI,
        angle1 = (angle + theta) * Math.PI / 180,
        angle2 = (angle - theta) * Math.PI / 180,
        topX = headlen * Math.cos(angle1),
        topY = headlen * Math.sin(angle1),
        botX = headlen * Math.cos(angle2),
        botY = headlen * Math.sin(angle2);
    ctx.save();

    ctx.beginPath();
    var arrowX = fromX - topX, arrowY = fromY - topY;
    ctx.moveTo(arrowX, arrowY);
    ctx.moveTo(fromX, fromY);
    ctx.lineTo(toX, toY);
    arrowX = toX + topX;
    arrowY = toY + topY;
    ctx.moveTo(arrowX, arrowY);
    ctx.lineTo(toX, toY);
    arrowX = toX + botX;
    arrowY = toY + botY;
    ctx.lineTo(arrowX, arrowY);
    ctx.stroke();
    ctx.restore();
}


export default toPen
