import ajax from "../util/ajax";

function createApi (apiDomain, token) {

    return {
        getOptionsByPage,
        addOption,
        getBoardData,
    };
    
    function getOptionsByPage (params, callback) {
        const {id, page} = params;
        const url = apiDomain + '/board/api/get-options?id=' + id + '&page=' + page;
        ajax({url, headers: {Authorization: token}, success: callback})
    }
    
    function addOption (params, callback) {
        const {id, page, data} = params;
        const url = apiDomain + '/board/api/add-option?id=' + id + '&page=' + page;
        ajax({url, headers: {Authorization: token}, method: 'POST', data, success: callback})
    }
    
    function getBoardData(id, callback) {
        const url = apiDomain + '/board/api/get-board?id=' + id;
        ajax({url, headers: {Authorization: token}, success: callback})
    }
}


export {
    createApi
}
