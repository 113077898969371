/** options: {
    url: string, //请求地址
    method: 'get' | 'post', //请求方式
    async: boolean, //是否异步
    data: object, //传输数据
    success: function, //成功之后的回调
    error: function, //失败之后的回调
    headers: object //对请求头进行设置
}* */

const defaultOptions = {
    method: 'GET',
    async: true,
    headers: {'Content-Type': 'application/x-www-form-urlencoded'}
};

function setRequestHeader(xhr, data) {
    for(let prop in data){
        xhr.setRequestHeader(prop,data[prop]);
    }
}

function ajax(options){
    options = {...defaultOptions, ...options};

    let xhr = null;

    if(window.XMLHttpRequest){
        xhr = new XMLHttpRequest()
    } else {
        xhr = new ActiveXObject("Microsoft.XMLHTTP");
    }
    // 连接
    xhr.open(options.method, options.url, options.async);
    setRequestHeader(xhr, options.headers);
    if(options.method === "GET"){
        xhr.send(null)
    } else if(options.method === "POST"){
        xhr.send(JSON.stringify(options.data));
    }
    xhr.onreadystatechange = function() {
        if (xhr.readyState === 4) {
            if (xhr.status === 200) {
                options.success && options.success({...JSON.parse(xhr.responseText), ...options.data});
            }
            if (xhr.status >= 300) {
                options.error(JSON.parse(xhr.status));
            }
        }
    };
}

export default ajax;
