import ajax from "../util/ajax";

function createWSOfUpdate ({apiDomain, id, token, interval}) {
    let currentCallback = function () {};
    let timer;

    return {
        subscribe,
        unsubscribe,
        close,
        update
    };

    function update() {
        if (interval) {
            clearInterval(timer);
            timer = setInterval(_getBoard, interval);
        } else {
            _getBoard();
        }
    }

    function subscribe (callback) {
        currentCallback = callback;
    }

    function unsubscribe () {
        currentCallback = function () {};
    }

    function close () {
        clearInterval(timer);
    }

    function _getBoard () {
        const url = apiDomain + '/board/api/get-board?id=' + id;
        ajax({url, headers: {Authorization: token}, success: res => currentCallback(res.options)});
    }
}

export {
    createWSOfUpdate
}
