import Nats from 'websocket-nats'

function createWebsocket (wsUrl, id) {

    let nats = Nats.connect({ 'servers': [wsUrl] });
    let listeners = {};
    let topic = `${id}_board_event`;

    return {
        subscribe,
        unsubscribe,
        close,
    };

    function subscribe (callback) {
        const list = listeners[topic] || [];
        list.push(nats.subscribe(topic, callback));
    }

    function unsubscribe () {
        if (!listeners[topic]) return;
        const len = listeners[topic].length;
        for(let i = 0; i < len; i++) {
            nats.unsubscribe(listeners[topic].pop());
        }
    }

    function close () {
        if (nats.wasConnected) {
            nats.close()
        } else {
            setTimeout(() => {
                nats.wasConnected && nats.close()
            }, 10000)
        }
    }
}

export {
    createWebsocket
}
