const R_MODE_LISTEN = 'listen';
const R_MODE_PAGE = 'page';
const R_MODE_TIME = 'time';
const KEY_UNDO = 'undo';
const KEY_REDO = 'redo';
const KEY_CLEAR = 'clear';
const KEY_CHANGE_PAGE = 'changePage';
const KEY_PEN_SUCCESS = 'penSuccess';
const KEY_PERCENT = 'percent';
const KEY_START = 'start';
const KEY_CLOSE = 'close';

export {
    R_MODE_LISTEN,
    R_MODE_PAGE,
    R_MODE_TIME,
    KEY_UNDO,
    KEY_REDO,
    KEY_CLEAR,
    KEY_CHANGE_PAGE,
    KEY_PEN_SUCCESS,
    KEY_PERCENT,
    KEY_START,
    KEY_CLOSE
}
