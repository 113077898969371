/*
*
* 可写端
* */

import {createApi} from "./repository/api";
import {createPaint} from "./repository/paint";
import {createMenu} from "./repository/menu";
import {KEY_CHANGE_PAGE, KEY_CLEAR, KEY_PEN_SUCCESS, KEY_REDO, KEY_UNDO, KEY_START, KEY_CLOSE} from "./constant";


function createRWBoard (options) {
    const {dom, menuDom, token, id, apiDomain = 'https://service.myun.tv', language = 'zh', i18n = {}, addOptionSuccess} = options;
    const paint = createPaint({dom});
    const api = createApi(apiDomain, token);
    const menu = createMenu({menuDom, paint, language, i18n});
    let currentPage = 1;
    const noRenderAction = [KEY_CHANGE_PAGE, KEY_START, KEY_CLOSE]

    getBoardData();
    paint.on(KEY_UNDO, () => api.addOption({id, page: currentPage, data: {key: KEY_UNDO}}, addOptionSuccess));
    paint.on(KEY_CLEAR, () => api.addOption({id, page: currentPage, data: {key: KEY_CLEAR}}, addOptionSuccess));
    paint.on(KEY_REDO, () => api.addOption({id, page: currentPage, data: {key: KEY_REDO}}, addOptionSuccess));
    paint.on(KEY_CHANGE_PAGE, data => api.addOption({id, page: currentPage, data:{key: KEY_CHANGE_PAGE, data: data.data}}, addOptionSuccess));
    paint.on(KEY_PEN_SUCCESS, data => api.addOption({id, page: currentPage,data}, addOptionSuccess));
    paint.on(KEY_START, () => api.addOption({id, page: currentPage, data: {key: KEY_START}}, addOptionSuccess));
    paint.on(KEY_CLOSE, () => api.addOption({id, page: currentPage, data: {key: KEY_CLOSE}}, addOptionSuccess));

    return {
        setCurrentPage,
        getCurrentPage,
        destroy,
        resize
    };

    function resize() {
        paint && paint.render();
    }

    function destroy() {
        paint && paint.destroy();
        menu && menu.destroy();
        paint.trigger(KEY_CLOSE)
    }

    function setCurrentPage(page) {
        paint.trigger(KEY_CHANGE_PAGE, {key: KEY_CHANGE_PAGE, data: [currentPage, page]});
        currentPage = page;
        getOptionsByPage(currentPage);
    }
    
    function getCurrentPage() { return currentPage; }

    function getOptionsByPage(page) {
        api.getOptionsByPage({id, page}, res => {
            if (res.errcode === 1000) {
                paint.clearOptions();
                res.options.forEach(item => {
                    if (typeof item.data === 'string') { item.data = JSON.parse(item.data); }
                    if (noRenderAction.indexOf(item.data.key) < 0) { paint.addUndoHistory(item.data); }
                    if (typeof item.timestamp === 'string') { item.timestamp = new Date(item.timestamp).getTime() }
                });
                paint.render();
            }
        })
    }

    function getBoardData() {
        api.getBoardData(id, res => {
            const boardData = _dealOptions(res.options);
            currentPage = _getCurrentPageByOptions(boardData) || 1;
            boardData.forEach(option => {
                if (option.page === currentPage && noRenderAction.indexOf(option.data.key) < 0) {
                    paint.addUndoHistory(option.data);
                }
            });
            paint.trigger(KEY_START)
            paint.render();
        })
    }

    function _dealOptions(options) {
        if (!options ) { options = [] }
        return options.map(option => {
            if (typeof option.data == 'string') { option.data = JSON.parse(option.data) }
            if (typeof option.timestamp === 'string') { option.timestamp = new Date(option.timestamp).getTime() }
            return option;
        });
    }
    
    function _getCurrentPageByOptions(boardData) {
        let newestPage = '';
        boardData.forEach(option => {
            if (option.data.key === KEY_CHANGE_PAGE) { newestPage = option.data.data[1] }
        });
        return newestPage;
    }
}

export {
    createRWBoard
}
