import {eraser0, eraser1, eraser2, pen0, pen1, pen2, pen3, pen4} from "../util/cursor-style";
import {cssStr} from "../util/css";
import {Language} from "./i18n";

function createMenu ({menuDom, paint, language, i18n}) {
    const allLanguage = Object.assign(Language, i18n);
    const Lang = allLanguage[language];
    const menuItems = [
        {name: Lang['color'], icon: 'color', type: 'color', onclick: setColor, list:['#ff2d2d', '#ff6400', '#ffc700', '#31a400', '#0085ff', '#b900ff', '#000000', '#ffffff']},
        {name: Lang['defaultPen'], icon: 'defaultPen', type: 'defaultPen', onclick: setDefaultPen, list: [4, 6, 10, 16, 20]},
        {name: Lang['eraser'], icon: 'eraser', type: 'eraser', onclick: setEraser, list: [40, 80, 120]},
        {name: Lang['font'], icon: 'font', type: 'textPen', onclick: setTextPen, list: [14, 16, 18, 20, 22, 24, 30, 36, 40, 50, 60]},
        {name: Lang['line'], icon: 'line', type: 'linePen', onclick: setLinePen, list: [4, 6, 10, 16, 20]},
        {name: Lang['arrow'], icon: 'arrow', type: 'toPen', onclick: setToPen, list: [4, 6, 10, 16, 20]},
        {name: Lang['ellipse'], icon: 'ellipse', type: 'ellipsePen', onclick: setEllipsePen, list: [4, 6, 10, 16, 20]},
        {name: Lang['rect'], icon: 'rect', type: 'rectPen', onclick: setRectPen, list: [4, 6, 10, 16, 20]},
        {name: Lang['move'], icon: 'move', type: 'noList', onclick: drag},
        {name: Lang['zoomIn'], icon: 'zoom-in', type: 'noList', onclick: zoomIn},
        {name: Lang['zoomOut'], icon: 'zoom-out', type: 'noList', onclick: zoomOut},
        {name: Lang['reset'], icon: 'reset', type: 'noList', onclick: reset},
        {name: Lang['undo'], icon: 'undo', type: 'noList', onclick: undo},
        {name: Lang['redo'], icon: 'redo', type: 'noList', onclick: redo},
        {name: Lang['clear'], icon: 'clear', type: 'noList', onclick: clear},
    ];
    const createMenuListItemContent = {
        defaultPen: createCommonPenContent,
        linePen: createCommonPenContent,
        toPen: createCommonPenContent,
        rectPen: createCommonPenContent,
        ellipsePen: createCommonPenContent,
        color: createColorContent,
        eraser: createEraserContent,
        textPen: createFontContent,
    };
    let menu;
    let menuWrap;
    let isPopConfirm = false;

    render();

    return {
        destroy
    };

    function destroy() {
        menuDom.removeChild(menuWrap);
    }

    function render() {
        createStyleCss(menuDom);

        menuWrap = document.createElement("div");
        menuWrap.classList.add('mudu-painter-menu-wrap');
        menuDom.appendChild(menuWrap);

        menu = document.createElement("div");
        menu.classList.add('mudu-painter-menu');
        menuWrap.appendChild(menu);

        menuItems.forEach(menuItem => {

            const btn = document.createElement('div');
            btn.classList.add('mudu-painter-menu-btn', 'mudu-' + menuItem.icon);
            btn.title = menuItem.name;
            btn.addEventListener('click', () => clickMenuBtn(menu, btn, menuItem));
            menu.appendChild(btn);

            if (menuItem.type === 'defaultPen') { btn.classList.add('active'); }
            if (menuItem.type === 'color') { createColorBlock(btn); }
            if (menuItem.type !== 'noList') { createSubmenuBtns(btn, menuItem); }
        });
        initStyle();
    }

    /*创建style 引入css*/
    function createStyleCss(menuDom) {
        const style = document.createElement('style');
        style.type = 'text/css';
        style.appendChild(document.createTextNode(cssStr));
        menuDom.appendChild(style);
    }

    function createColorBlock(btn) {
        const colorBlock = document.createElement('div');
        colorBlock.classList.add('mudu-color-block');
        btn.appendChild(colorBlock);
    }

    function createSubmenuBtns(btn, menuItem) {
        btn.classList.add('mudu-painter-menu-pen');

        const submenuWrap = document.createElement('div');
        submenuWrap.classList.add('mudu-submenu-wrap');
        btn.appendChild(submenuWrap);

        menuItem.list.forEach((listItem, index) => {
            const submenuBtn = document.createElement('div');
            submenuBtn.addEventListener('click', e => clickSubmenuBtn(e, submenuWrap, menuItem, listItem, index));
            submenuWrap.appendChild(submenuBtn);
            createMenuListItemContent[menuItem.type](submenuBtn, listItem);
        });
        submenuWrap.children[0].classList.add('active');
    }

    function initStyle() {
        const initColor = menuItems.filter(item => item.type === 'color')[0].list[0];
        const initPenLineWidth = menuItems.filter(item => item.type === 'defaultPen')[0].list[0];
        const initEraser = menuItems.filter(item => item.type === 'eraser')[0].list[0];
        const initFontSize = menuItems.filter(item => item.type === 'textPen')[0].list[0];
        setColor(initColor);
        paint.setStyle('customFontSize', initFontSize);
        paint.setStyle('customEraser', initEraser);
        setDefaultPen(initPenLineWidth, 0);
    }

    function clickMenuBtn(menu, btn, menuItem) {
        const addActiveNameList = [Lang['defaultPen'], Lang['eraser'], Lang['font'], Lang['line'], Lang['arrow'], Lang['ellipse'], Lang['rect'], Lang['move']];
        if (addActiveNameList.indexOf(menuItem.name) > -1) {
            const menuBtns = menu.children;
            for(let i = 0; i < menuBtns.length; i++) {
                menuBtns[i].classList.remove('active');
            }
            btn.classList.add('active');
        }
        if (menuItem.type === 'noList') {
            if (menuItem.name === Lang['clear']) {
                menuItem.onclick(btn);
            } else {
                menuItem.onclick();
            }
        } else {
            let children = btn.getElementsByClassName('mudu-submenu-wrap')[0].children;
            for(let i = 0;i < children.length; i++) {
                if (children[i].className.indexOf('active') > -1) {
                    menuItem.onclick(menuItem.list[i], i);
                }
            }
        }

    }
    
    function clickSubmenuBtn(e, submenuWrap, menuItem, listItem, index) {
        const addActiveNameList = [Lang['defaultPen'], Lang['eraser'], Lang['font'], Lang['line'], Lang['arrow'], Lang['ellipse'], Lang['rect']];
        if (addActiveNameList.indexOf(menuItem.name) > -1) {
            const menuBtns = submenuWrap.parentElement.parentElement.children;
            for(let i = 0; i < menuBtns.length; i++) {
                menuBtns[i].classList.remove('active');
            }
            submenuWrap.parentElement.classList.add('active');
        }

        const submenuBtns = submenuWrap.children;
        for(let i = 0; i < submenuBtns.length; i++) {
            submenuBtns[i].classList.remove('active');
        }
        submenuBtns[index].classList.add('active');
        menuItem.onclick(listItem, index);
        e.stopPropagation()
    }

    /**/
    function setColor(color) {
        paint.setStyle('fillStyle', color);
        paint.setStyle('strokeStyle', color);
        document.getElementsByClassName('mudu-color-block')[0].style.backgroundColor = color;
    }

    function setDefaultPen(customLineWidth, index) {
        const cursorStyle = [pen0, pen1, pen2, pen3, pen4];
        paint.setStyle('customLineWidth', customLineWidth);
        paint.setCurrentPen('defaultPen');
        paint.setCursor(cursorStyle[index]);
    }
    
    function setEraser(eraser, index) {
        const cursorStyle = [eraser0, eraser1, eraser2];
        paint.setStyle('customEraser', eraser);
        paint.setCurrentPen('eraser');
        paint.setCursor(cursorStyle[index]);
    }
    
    function setTextPen(fontSize) {
        paint.setStyle('customFontSize', fontSize);
        paint.setCurrentPen('textPen');
        paint.setCursor('text');
    }

    function setLinePen(customLineWidth) {
        paint.setStyle('customLineWidth', customLineWidth);
        paint.setCurrentPen('linePen');
        paint.setCursor('auto');
    }

    function setToPen(customLineWidth) {
        paint.setStyle('customLineWidth', customLineWidth);
        paint.setCurrentPen('toPen');
        paint.setCursor('auto');
    }

    function setEllipsePen(customLineWidth) {
        paint.setStyle('customLineWidth', customLineWidth);
        paint.setCurrentPen('ellipsePen');
        paint.setCursor('auto');
    }

    function setRectPen(customLineWidth) {
        paint.setStyle('customLineWidth', customLineWidth);
        paint.setCurrentPen('rectPen');
        paint.setCursor('auto');
    }
    
    function drag() {
        paint.startDrag();
        paint.setCursor('move');
    }
    
    function zoomIn() {
        const scale = paint.getScale() * 1.1;
        if (scale < 10) { paint.setScale(scale);}
    }
    
    function zoomOut() {
        const scale = paint.getScale() / 1.1;
        if (scale > 0.5) { paint.setScale(scale); }
    }

    function reset() {
        paint.resetDragState();
    }

    function undo() {
        paint.undo();
    }

    function redo() {
        paint.redo();
    }

    function clear(btn) {
        if (isPopConfirm) return;
        isPopConfirm = true;

        const pop = createElement('div', 'mudu-clear-pop', Lang['clearConfirmText']);
        btn.appendChild(pop);

        const btnWrap = createElement('div', 'mudu-pop-btn-wrap');
        pop.appendChild(btnWrap);

        const cancelBtn = createElement('div', 'mudu-cancel-btn', Lang['clearConfirmCancel']);
        const confirmBtn = createElement('div', 'mudu-confirm-btn', Lang['clearConfirm']);
        btnWrap.appendChild(cancelBtn);
        btnWrap.appendChild(confirmBtn);

        cancelBtn.addEventListener('click', cancel);
        confirmBtn.addEventListener('click', confirm);

        function cancel(e) {
            isPopConfirm = false;
            confirmBtn.removeEventListener('click', confirm);
            cancelBtn.removeEventListener('click', cancel);
            btn.removeChild(pop);
            e && e.stopPropagation();
        }

        function confirm(e) {
            paint.setUndoHistory([]);
            cancel();
            createSuccess();
            e && e.stopPropagation();
        }

        function createSuccess() {
            const success = createElement('div', 'mudu-pop-success', Lang['clearWarn']);
            success.style.pointerEvents = 'none';
            btn.appendChild(success);
            setTimeout(() => {
                btn.removeChild(success);
            }, 1000)
        }
    }

    function createElement(tag, className, text) {
        const div = document.createElement(tag);
        div.classList.add(className);
        if (text) div.innerText = text;
        return div;
    }

    /*以下方法用于创建下拉框具体内容*/
    function createCommonPenContent(submenuBtn, listItem) {
        submenuBtn.classList.add('mudu-common-item');

        const circle = document.createElement('div');
        circle.style.width = `${listItem}px`;
        circle.style.height = `${listItem}px`;
        submenuBtn.appendChild(circle);
    }

    function createColorContent(submenuBtn, listItem) {
        submenuBtn.classList.add('mudu-color-item');

        const colorDiv = document.createElement('div');
        colorDiv.style.backgroundColor = listItem;
        submenuBtn.appendChild(colorDiv);
    }

    function createEraserContent(submenuBtn, listItem) {
        submenuBtn.classList.add('mudu-eraser-item');
        submenuBtn.innerText = listItem + 'px';
    }

    function createFontContent(submenuBtn, listItem) {
        submenuBtn.classList.add('mudu-font-item');
        submenuBtn.innerText = `${listItem}px`;
    }
}

export {
    createMenu
}
