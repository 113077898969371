import pens from "./pens";
import {normalizeEvent, offset, warpData} from "../util/service";
import Drag from "../util/Drag";
import Event from "../util/Event";
import {KEY_CLEAR, KEY_PEN_SUCCESS, KEY_PERCENT, KEY_REDO, KEY_UNDO} from "../constant";

function createPaint(arg) {
    const {dom} = arg;
    const eventAction = Event();
    let undoHistory = [];
    let redoHistory = [];
    let drag = null;
    let currentPenData = [];
    let box = {};
    let beginPoint = false;
    let outPoint = false;
    let currentScale = 1;
    let currentPen = null;
    let currentPenInfo = { name: '', pen: '' };
    let currentStyle = {
        fillStyle: '',
        strokeStyle: '',
        customLineWidth: '',
        customFontSize: '',
        customEraser: '',
    };

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.className = "painter-canvas";
    dom.appendChild(canvas);
    _resize();

    canvas.addEventListener("mousedown", _mousedown);
    document.addEventListener("mousemove", _mousemove);
    document.addEventListener("mouseup", _mouseup);
    canvas.addEventListener("touchstart", _mousedown);
    document.addEventListener("touchmove", _mousemove);
    document.addEventListener("touchend", _mouseup);
    canvas.addEventListener("mouseover", _mouseover);
    canvas.addEventListener("mouseout", _mouseout);

    return {
        setStyle,
        getStyle,
        setCursor,
        getContainer,
        setScale,
        getScale,
        setDisable,
        startDrag,
        stopDrag,
        resetDragState,
        setCurrentPen,
        redo,
        undo,
        addUndoHistory,
        setUndoHistory,
        setRedoHistory,
        render,
        clearOptions,
        destroy,
        on,
        trigger,
        off,
    };

    function setStyle(key, value) {
        currentStyle[key] = value;
        if (!currentStyle[key]) {
            throw new Error('style not found')
        }
    }
    function getStyle() {
        return currentStyle;
    }
    function setCursor(cursor) {
        canvas.style.cursor = cursor;
    }

    function getContainer() {
        return dom;
    }
    function setScale(scale) {
        if (currentScale === scale) return;
        currentScale = scale;
        dom.style.transform = 'scale(' + currentScale + ')';
    }
    function getScale() {
        return currentScale;
    }
    function setDisable(isDisable) {
        canvas.style.pointerEvents = isDisable ? 'none' : 'auto';
    }

    function startDrag() {
        setDisable(true);
        if (!(dom.parentElement.style.position === 'relative' || dom.parentElement.style.position === 'absolute')) {
            dom.parentElement.style.position = 'relative';
        }
        if (!drag) {
            dom.style.position = 'absolute';
            drag = new Drag(dom, dom).start();
        } else {
            drag.start();
        }
    }
    function stopDrag() {
        drag.stop();
    }
    function resetDragState() {
        setScale(1);
        dom.style.left = '';
        dom.style.top = '';
        if (drag) {
            drag.state.end.left = 0;
            drag.state.end.top = 0;
            drag.stop();
        }
    }

    function setCurrentPen(penName = 'defaultPen') {
        let tmp = pens.filter(p => penName === p.name)[0];
        if (!tmp) {
            throw new Error('pen not found')
        } else {
            // 清除没有完成的画笔数据
            if (currentPenInfo.name !== tmp.name) {
                currentPenInfo = tmp;
                currentPenData = [];
            }
            if (drag) {
                stopDrag();
            }
            setDisable(false);
            _createNewPen();
        }
    }

    function redo() {
        if (redoHistory.length > 0) {
            const data = redoHistory.pop();
            undoHistory.push(data);
            trigger(KEY_REDO, data);
            render();
        }
    }
    function undo() {
        if (undoHistory.length > 0) {
            const data = undoHistory.pop();
            redoHistory.push(data);
            trigger(KEY_UNDO, data);
            render();
        }
    }

    function addUndoHistory(data) {
        if (data.key === KEY_UNDO) {
            if (undoHistory.length > 0) {
                let data = undoHistory.pop();
                redoHistory.push(data);
            }
            return;
        }
        if (data.key === KEY_REDO) {
            if (redoHistory.length > 0) {
                let data = redoHistory.pop();
                undoHistory.push(data);
            }
            return;
        }

        if (data.key === KEY_PERCENT) {
            setScale(data.data[0]);
        }

        if (data.key === KEY_CLEAR) {
            undoHistory = [];
            return
        }
        undoHistory.push({key: data.key, data: data.data, style: data.style});
        currentPenData = [];
    }
    function setUndoHistory(data) {
        if (data.length === 0) {
            trigger(KEY_CLEAR);
        }
        undoHistory = data;
        render();
    }
    function setRedoHistory() {}

    function render() {
        _resize();
        // 清除画布
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        undoHistory.forEach(item => {
            _paintItem(ctx, item, canvas);
        });
        if (typeof currentPenData !== "undefined" && currentPenData.length !== 0) {
            ctx.save();
            currentPenInfo.pen.render(ctx, warpData(currentPenData, canvas), currentStyle);
            ctx.restore();
        }
    }
    function clearOptions() {
        undoHistory = [];
        redoHistory = [];
        currentPenData = [];
    }
    function destroy() {
        dom.style.left = '';
        dom.style.top = '';
        setScale(1);
        eventAction.clear();
        clearOptions();
        canvas.removeEventListener("mousedown", _mousedown);
        document.removeEventListener("mousemove", _mousemove);
        document.removeEventListener("mouseup", _mouseup);
        canvas.removeEventListener("touchstart", _mousedown);
        document.removeEventListener("touchmove", _mousemove);
        document.removeEventListener("touchend", _mouseup);
        canvas.removeEventListener("mouseover", _mouseover);
        canvas.removeEventListener("mouseout", _mouseout);
        dom.removeChild(canvas);
    }      // event取消，dom删掉，timer清除，数据清除

    function on(type, callback) {
        eventAction.on(type, callback);
    }
    function trigger(type, data) {
        eventAction.trigger(type, {...data})
    }
    function off(type, callback) {
        eventAction.off(event,  callback)
    }


    function _createNewPen() {
        currentPen = new currentPenInfo.pen(_setData, _penSuccess, _append)
    }
    function _setData(data) {
        if (typeof data === "undefined" || data.length === 0) return;
        currentPenData = data;
        render();
    }

    function _resize() {
        if (dom.offsetWidth !== canvas.width) { canvas.width = dom.offsetWidth; }
        if (dom.offsetHeight !== canvas.height) { canvas.height = dom.offsetHeight; }
    }

    // 画笔绘制结束回调
    function _penSuccess(data) {
        _createNewPen();
        if (typeof data !== 'undefined' && data.length !== 0) {
            trigger(KEY_PEN_SUCCESS, {key: currentPenInfo.name, data, style: currentStyle});
            undoHistory.push({key: currentPenInfo.name, data, style: {...currentStyle}});
            currentPenData = [];
            redoHistory = [];
            render();
        }
    }
    function _append(div, x, y) {
        if (div) {
            div.className = "mudu-painter-canvas__item";
            if (x != null) div.style.left = warpData(x, canvas) + "px";
            if (y != null) div.style.top = warpData(y, canvas, 1) + "px";
            dom.style.position = 'relative';
            dom.appendChild(div);
        }
        return dom;
    }
    function _paintItem(ctx, item, r) {
        if (typeof item != "object") return;
        let pen = pens.filter(penInfo => penInfo.name === item.key)[0].pen;
        if (pen) {
            ctx.save();
            pen.render(ctx, warpData(item.data, r), item.style);
            ctx.restore();
        }
    }

    function _mousedown(event) {
        if (event.target !== canvas) {
            return;
        }
        box = offset(canvas);
        let e = normalizeEvent(event, canvas, box);
        if (!beginPoint) beginPoint = {x: e.offsetX, y: e.offsetY};
        if (typeof currentPen.begin == "function") {
            currentPen.begin(beginPoint.x / currentScale, beginPoint.y / currentScale);
        }
        event.preventDefault();
    }
    function _mousemove(event) {
        let e = normalizeEvent(event, canvas, box);
        if (!beginPoint) return;
        if (typeof currentPen.move == "function") {
            currentPen.move(beginPoint.x / currentScale, beginPoint.y / currentScale, e.offsetX / currentScale, e.offsetY / currentScale);
        }
    }
    function _mouseup(event) {
        let e = normalizeEvent(event, canvas, box);
        _end({x: e.offsetX, y: e.offsetY});
    }
    function _mouseover(event) {
        if (event.buttons !== 1) {
            _end(outPoint, "mouseover");
        }
        outPoint = false;
    }
    function _mouseout(event) {
        let e = normalizeEvent(event, canvas, box);
        outPoint = {x: e.offsetX, y: e.offsetY};
        if (currentPenInfo.pen.outEnd) {
            _end(outPoint, "mouseout");
        } else if (event.buttons === 1) {
            _mousemove(e);
        }
    }
    function _end(endPoint, callBy) {
        if (!beginPoint) return;
        if (typeof currentPen.end == "function") {
            currentPen.end(beginPoint.x / currentScale, beginPoint.y / currentScale, endPoint.x / currentScale, endPoint.y / currentScale, callBy);
        }
        beginPoint = false;
    }

}

export {
    createPaint
}
